import React from 'react'
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  EmailShareButton,
} from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  GooglePlusIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  EmailIcon,
} from 'react-share';

export default class ShareButtons extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 mb-4">

          <FacebookShareButton className="share-button" url={this.props.url}>
            <FacebookIcon size={36} round={true}></FacebookIcon>
          </FacebookShareButton>

          <TwitterShareButton className="share-button ml-3" url={this.props.url}>
            <TwitterIcon size={36} round={true}></TwitterIcon>
          </TwitterShareButton>

          <TelegramShareButton className="share-button ml-3" url={this.props.url}>
            <TelegramIcon size={36} round={true}></TelegramIcon>
          </TelegramShareButton>

          <LinkedinShareButton className="share-button ml-3" url={this.props.url}>
            <LinkedinIcon size={36} round={true}></LinkedinIcon>
          </LinkedinShareButton>

          <GooglePlusShareButton className="share-button ml-3" url={this.props.url}>
            <GooglePlusIcon size={36} round={true}></GooglePlusIcon>
          </GooglePlusShareButton>

          <WhatsappShareButton className="share-button ml-3" url={this.props.url}>
            <WhatsappIcon size={36} round={true}></WhatsappIcon>
          </WhatsappShareButton>

          <VKShareButton className="share-button ml-3" url={this.props.url}>
            <VKIcon size={36} round={true}></VKIcon>
          </VKShareButton>

          <TumblrShareButton className="share-button ml-3" url={this.props.url}>
            <TumblrIcon size={36} round={true}></TumblrIcon>
          </TumblrShareButton>

          <ViberShareButton className="share-button ml-3" url={this.props.url}>
            <ViberIcon size={36} round={true}></ViberIcon>
          </ViberShareButton>

          <WorkplaceShareButton className="share-button ml-3" url={this.props.url}>
            <WorkplaceIcon size={36} round={true}></WorkplaceIcon>
          </WorkplaceShareButton>


        </div>
      </div>


    );
  }
}